import './members.scss'
import { setUserId } from '@convivainc/conviva-js-appanalytics'

import Cookies from 'js-cookie'
import { Response } from 'js-api'
import Popover from 'popover'

import notificationsApi from 'networking/api/members/notifications'
import sessionsApi from 'networking/api/members/sessions'

const MEMBER_DATA_COOKIE = '_grnsw-member'

class MembersController {

  constructor() {
    document.addEventListener('grnsw:load', () => this.updateState())
  }

  updateState() {
    this.loadCookie()

    if (this.isLoggedIn) {
      document.body.classList.add('members--loggedin')
      document.body.classList.remove('members--loggedout')
      this.cookie.id ? setUserId(this.cookie.id.toString()) : setUserId('GUEST')
    } else {
      document.body.classList.remove('members--loggedin')
      document.body.classList.add('members--loggedout')
      setUserId('GUEST')
    }

    document.querySelectorAll('[data-members-name]').forEach((element) => {
      let style = element.getAttribute('data-members-name')
      element.innerText = this.name(style)
    })
  }

  ////////// Data

  get isLoggedIn() {
    return !!this.cookie
  }

  loadCookie() {
    let cookie = Cookies.getJSON(MEMBER_DATA_COOKIE)
    if (cookie !== this.cookie) {
      this.cookie = cookie
    }
  }

  name(style) {
    if (!this.isLoggedIn) { return null }

    switch (style) {
      case 'given':
        return this.cookie.given_name
      case 'family':
        return this.cookie.family_name
      case 'full':
        return [this.cookie.given_name, this.cookie.family_name].join(' ')
    }
  }

  ////////// Session

  showLogin(callback) {
    let popover = new Popover({
      removeOnHide: true,
      closeCallback: callback
    })
    popover.loadContent('/member/session')
    popover.show()
  }

  sendPageView() {
    if (!this.isLoggedIn) { return }

    sessionsApi.pageView().then((response) => {
      if (response.success) {
        document
          .querySelectorAll('[data-notification-count]')
          .forEach((element) => {
            let count = response.content.unread_notification_count
            if (count > 0) {
              element.setBadge(count, { color: 'red' })
            } else {
              element.setBadge(null)
            }
          })
      }
    })
  }

  ////////// Notifications

  sendTestNotification() {
    notificationsApi.test()
  }
}

let members = new MembersController()
export default members

////////// UJS

let pageViewTimeout = null

document.addEventListener('turbolinks:visit', (_event) => {
  if (pageViewTimeout) {
    clearTimeout(pageViewTimeout)
    pageViewTimeout = null
  }
})

document.addEventListener('turboujs:load', (_event) => {
  members.updateState()

  pageViewTimeout = setTimeout(() => {
    members.sendPageView()
    pageViewTimeout = null
  }, 1000)
})

document.addEventListener('turbolinks:request-end', (event) => {
  let xhr = event.data.xhr
  let response = new Response(xhr)

  if (response.status === 401 && response.contentType === 'text/html') {
    window.Turbolinks.controller.currentVisit.loadResponse = function() {}

    let popover = new Popover({
      removeOnHide: true
    })
    popover.displayContent(response.content)
    popover.closeClicked = (_event) => window.history.back()
    popover.backgroundClicked = (_event) => window.history.back()
    popover.show()
  }
})
