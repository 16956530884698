require.context('../images', true)

import Conviva from '@convivainc/conviva-js-coresdk/conviva-core-sdk.js'
import ConvivaHtml5Module from '@convivainc/conviva-js-html5/conviva-html5native-impl.js'
window.ConvivaHtml5Module = ConvivaHtml5Module
window.Conviva = Conviva

import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics'
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing'
import { ErrorTrackingPlugin, enableErrorTracking } from '@convivainc/conviva-js-appanalytics-error-tracking'
import { LinkClickTrackingPlugin, enableLinkClickTracking, enableButtonClickTracking } from '@convivainc/conviva-js-appanalytics-click-tracking'

convivaAppTracker({
  appId: 'TheDogsAU - Web',
  convivaCustomerKey: window.config.convivaKey,
  appVersion: window.config.appVersion,
  contexts: {
    performanceTiming: true
  },
  plugins: [
    PerformanceTimingPlugin(),
    ErrorTrackingPlugin(),
    LinkClickTrackingPlugin()
  ]
})

enableLinkClickTracking()
enableButtonClickTracking()
enableErrorTracking()

import FormattedTime from 'formatted-time'
import 'js-alerts'
import 'dropdown'
import 'field-container'
import 'onspace/assets/elements/calendar'
import 'onspace/assets/elements/fields/rich_text.scss'
import 'onspace/assets/elements/form'
import 'onspace/assets/elements/pagination'
import 'onspace/assets/elements/popover'
import 'onspace/assets/elements/toggle'
import 'scss-utils/button'
import 'scss-utils/layout'

import 'base/color.scss'
import 'base/layout.scss'
import 'base/typography.scss'
import 'base/reset.scss'

import analytics from 'components/analytics'
import 'components/assets'
import 'components/links'
import 'components/networking'
import 'components/scroll'
import 'components/ujs'

import members from '../components/members'

import 'elements/advertising/placement'
import 'elements/advertising/offer'
import 'elements/accordion'
import 'elements/badge'
import 'elements/button'
import 'elements/collection'
import 'elements/content'
import 'elements/image_gallery'
import 'elements/layout.scss'
import 'elements/popover'
import 'elements/tabs'
import 'elements/grid'
import 'elements/page'
import 'elements/rich_text'
import 'elements/table'
import 'elements/footer'
import 'elements/list'
import 'elements/map'
import 'elements/nav_bar'
import 'elements/search/autocomplete'
import 'elements/search/form'
import 'elements/search/list'
import 'elements/share'
import 'elements/video'
import 'elements/video/card'
import 'elements/video/iframe'
import 'elements/video/live'
import 'elements/recaptcha'

import '../elements/blackbook/dog'
import '../elements/blackbook/entries'
import '../elements/blackbook/items'
import '../elements/blackbook/last_start_cell'
import '../elements/blackbook/parent'
import '../elements/blackbook/trainer'
import '../elements/box_history/table'
import '../elements/progeny/table'
import '../elements/dog/content'
import '../elements/dog/general_info'
import '../elements/dog/general_info_expert_form'
import '../elements/dog/race_result'
import '../elements/dog/best_win_tracks'
import '../elements/feature/header'
import '../elements/feature/content'
import '../elements/feature/past_winners'
import '../elements/feature/premiership'
import '../elements/feature/premiership_filter_form'
import '../elements/home/content'
import '../elements/meeting/grid'
import '../elements/meeting/header'
import '../elements/meeting/list'
import '../elements/meeting/print_hub'
import '../elements/meeting/the_hound/header'
import '../elements/meeting/the_hound/cards'
import '../elements/meeting/the_hound/daily_digest'
import '../elements/meeting/races'
import '../elements/news/article'
import '../elements/news/card'
import '../elements/news/list'
import '../elements/notifications'
import '../elements/race/book/header'
import '../elements/race/book/form'
import '../elements/race/book/race'
import '../elements/race/box'
import '../elements/race/buttons'
import '../elements/race/header'
import '../elements/race/list'
import '../elements/race/media'
import '../elements/race/runners'
import '../elements/race/runners_summary'
import '../elements/race/preview'
import '../elements/race/racing_result_tab'
import '../elements/runner/odd'
import '../elements/runner/odds_fluctuation_high'
import '../elements/runner/odds_fluctuation_low'
import '../elements/runner/odd_fluctuation_graph'
import '../elements/runner/expert_form'
import '../elements/runner/show_runner_form'
import '../elements/runner/result_cell'
import '../elements/runner/stats'
import '../elements/runner/table'
import '../elements/ticker'
import '../elements/tutorial'
import '../elements/track/card'
import '../elements/track/content'
import '../elements/track/track_distance_record'
import '../elements/track/header'
import '../elements/track/map'
import '../elements/calendar/header'
import '../elements/calendar/form_filter'
import '../elements/calendar/meeting'
import '../elements/calendar/radio_button'
import '../elements/calendar/form'
import '../elements/calendar/desktop_form'
import '../elements/merchandise/divider'
import '../elements/calendar/table'
import '../elements/calendar/table_desktop'
import '../elements/calendar/scroll'
import '../elements/stud_dogs/main'
import '../elements/stud_dogs/profile'
import 'chartkick/chart.js'

window.members = members
window.analytics = analytics

FormattedTime.defaultLocale = 'en-AU'
FormattedTime.formats.datetime_short = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' }
FormattedTime.formats.month_short = { year: '2-digit', month: 'short' }
FormattedTime.formats.month_long = { year: 'numeric', month: 'long' }
FormattedTime.formats.time_24 = { hour: '2-digit', minute: '2-digit', hour12: false }

analytics.injectGoogleAnalytics(window.config.googleAnalyticsId)
analytics.injectGoogleTagManager(window.config.googleTagId)
analytics.setupTurbolinksTracking()
analytics.setupConviva(window.config.convivaKey, window.config.environment === 'development')
